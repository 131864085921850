.app-bar {
    background: var(--Green, #3ABF7C);
    height: 4rem;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 0 2rem;
    &-title{
        color: white;
        font-family: fredoka;
        font-size: 1.2rem;
    }
    position: fixed;
    top: 0;
    right: 0;
    left: 0;

    &-close{
        width: 2rem;
        height: 2rem;
        background-image: url(./../../assets/images/close.png);
        background-size: 50%;
        background-position: 50%;
        background-repeat: no-repeat;
        border-radius: 1rem;
        transition: all .2s;

        &:hover{
            background-color: rgba(255, 255, 255, 0.2);
        }
        &:active{
            background-color: rgba(255, 255, 255, 0.4);
        }
    }
    &-back{
        width: 2rem;
        height: 2rem;
        background-image: url(./../../assets/images/back.png);
        background-size: 50%;
        background-position: 50%;
        background-repeat: no-repeat;
        border-radius: 1rem;
        transition: all .2s;

        &:hover{
            background-color: rgba(255, 255, 255, 0.2);
        }
        &:active{
            background-color: rgba(255, 255, 255, 0.4);
        }
    }

    &-leading{
        width: 2rem;
    }

}

