.verify-code {
    padding: 4rem 0 0 0;
    

    &-header{
        padding: 2rem 0;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 1rem;

        &-title {
            color: var(--Dark, #080E1E);
            font-family: fredoka;
            text-align: center;
        }
        &-text {
            color: var(--Dark, #080E1E);
            font-family: fredoka;
            text-align: center;
        }
        &-change-text {
            color: #3ABF7C;
            font-family: fredoka;
            padding: 5px;
            border-radius: 3px;
            text-align: center;
            // text-decoration: underline;
            transition: all .2s;
            user-select: none;

            &:hover{
                color: #fff;
                transform: translateY(-3px);
                background-color: #3ABF7C;
                cursor: pointer;
            }
            &:active{
                color: #3ABF7C;
                transform: translateY(0);
                background-color: #fff;
                cursor: pointer;
            }
        }
    }
    &-content{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        &-form {
            width: 50%;
        }



        &-info {
            width: 50%;
            display: flex;
            flex-direction: row;
            justify-content: flex-start;
            align-items: center;
            margin-top: 1rem;
            margin-bottom: 1rem;

            &-text {
                color:#363B44;
                font-size: 1rem;
                font-family: fredoka;
            }
        }
    }
    &-image{
        
        width: 30vw;
        height: 30vw;
        border-radius: 1.75rem;
        margin: 1rem 0 2rem 0;
    }
    



        &-btn {
            height: 8vh;
            width: 50%;
            background-color: var(--Green, #3ABF7C);
            border-radius: 1rem;
            display: flex;
            justify-content: center;
            align-items: center;
            transition: all .2s;
            user-select: none;
            margin-top: 2rem;
            margin-bottom: 2rem;



            &:hover{
                background-color: #43db8f;
                cursor: pointer;
            }
            &:active{
                background-color: #4bf39f;
                cursor: pointer;
            }

            // &:active > &-text {
            //     color:white;
            // }

            &-text {
                color:white;
                font-size: 1.2rem;
                font-family: fredoka;
            }
        
    }

        &-select-btn-list {
            width: 50%;
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: center;
            gap: 2rem;
            margin-bottom: 2rem;
    }

        &-select-btn {
            height: 8vh;
            width: 50%;
            background-color: rgba(8, 14, 30, 0.05);
            border-radius: 1rem;
            display: flex;
            justify-content: center;
            align-items: center;
            transition: all .2s;
            user-select: none;
            

            &-selected {
                background-color: rgba(8, 14, 30, 0.2);
            }


            &:hover{
                background-color: rgba(8, 14, 30, 0.1);
                cursor: pointer;
            }
            &:active{
                background-color: rgba(8, 14, 30, 0.2);
                cursor: pointer;
            }

            // &:active > &-text {
            //     color:white;
            // }

            &-text {
                color:rgba(101, 104, 114, 0.50);
                font-size: 1.2rem;
                font-family: fredoka;
                @media only screen and (max-width: 1003px) {
                    font-size: 1rem;
                }
            }
        
    }

    &-resend {
        width: 50%;
        height: 3rem;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        gap: 1rem;
        &-text {
            color: var(--Dark, #080E1E);
            font-family: fredoka;
            text-align: center;
        }
        &-btn {
            color: #3ABF7C;
            font-family: fredoka;
            padding: 5px;
            border-radius: 3px;
            text-align: center;
            // text-decoration: underline;
            transition: all .2s;
            user-select: none;

            &:hover{
                color: #fff;
                transform: translateY(-3px);
                background-color: #3ABF7C;
                cursor: pointer;
            }
            &:active{
                color: #3ABF7C;
                transform: translateY(0);
                background-color: #fff;
                cursor: pointer;
            }
        }
    }
}



label {
    font-family: fredoka;
    font-size: 1rem;
}

.verify-input {
    width: 3.5rem;
    height: 3.5rem;
    padding: 12px 20px;
    margin: 0 1rem;
    border: none;
    display: inline-block;
    border-radius: 1rem;
    box-sizing: border-box;
    font-family: fredoka;
    color: #080E1E;
    font-size: 1rem;
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
    background-color: rgba(8, 14, 30, 0.05);
  }