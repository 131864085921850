.overview-item {
    width: 15vw;
    height: 15vw;
    border-radius: 3vw;
    background: #FFF;
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 1rem;
    margin-bottom: 1rem;

    @media only screen and (max-width: 1003px) {
        width: 35vw;
        height: 35vw;
    }

    &-icon {
        width: 3rem;
        height: 3rem;
        border-radius: 0.5rem;
        background: #FFEDF1;
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 10px;

        &-image {
            width: 1.5rem;
            height: 1.5rem;
        }
    }
    &-text {
        color: #000;
        text-align: center;
        font-family: fredoka;
        font-size: 1rem;
        font-style: normal;
        font-weight: 500;
        line-height: 1.1875rem;
    }

}