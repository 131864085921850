.day{
    width: 3rem;
    height: 3rem;
    padding: .5rem;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 0.5rem;
    margin: 1rem 0;
    transition: all .2s;
    box-shadow: 0 1px 2rem 0 rgba(0, 0, 0, 0.1);
    user-select: none;
    

    &-inactive{
        background-color: white;
    }
    &-active{   
        background-color:  #FF6AA0;
    }

    
    &:hover{
        background-color:  #faa5c4;
        cursor: pointer;
    }
    &:active{
        background-color: #FF6AA0;
        cursor: pointer;

    }

    

    &-text {
        font-size: 1rem;
        font-family: fredoka;

        &-active{
            color: white;
        }

        &-inactive{
            color: #5A5A5A;
        }
    }


}