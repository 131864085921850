.question{
    width: 100%;
    height: auto;
    padding: .5rem;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 2rem;
    border-radius: 0.5rem;
    margin: 1rem 0;
    transition: all .2s;
    box-shadow: 0 1px 2rem 0 rgba(0, 0, 0, 0.1);
    user-select: none;
    

    &-inactive{
        background-color: white;
    }
    &-active{   
        background-color:  #FF6AA0;
    }

    
    &:hover{
        background-color:  #faa5c4;
        cursor: pointer;
    }
    &:active{
        background-color: #FF6AA0;
        cursor: pointer;

    }

    &-icon {
        width: 2.5rem;
        height: 2.5rem;
        border-radius: .5rem;
        background-color: #FFEDF1;
        display: flex;
        justify-content: center;
        align-items: center;

        &-image{
            width: 1rem;
            height: 1rem;
        }
    }

    &-info {
        width: 90%;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
    }
    &-text {
        font-size: 1rem;
        font-family: fredoka;

        &-active{
            color: white;
        }

        &-inactive{
            color: #5A5A5A;
        }
    }
    &-desc {
        font-size: .7rem;
        font-family: fredoka;

        &-active{
            color: white;
        }

        &-inactive{
            color: #5A5A5A;
        }
    }


}