.purchase {
    width: 100vw;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;

    &-container {
        width: 30rem;
        height: 20rem;
        border-radius: 3rem;
        background: #FFF;
        box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 1rem;

    }
    &-title {
        color: #3ABF7C;
        font-family: fredoka;
        text-align: center;
        font-size: 1.5rem;
        font-style: normal;
        font-weight: 700;
        text-align: center;
    }
    &-text {
        color: #41464B;
        font-family: fredoka;
        text-align: center;
        font-size: 1rem;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        letter-spacing: 0.01rem;
    }

  
    

    &-btn-list {
        width: 50%;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        gap: 2rem;
}

        &-yes-btn {
            height: 3rem;
            width: 40%;
            background-color: var(--Green, #3ABF7C);
            border-radius: 1rem;
            display: flex;
            justify-content: center;
            align-items: center;
            transition: all .2s;
            user-select: none;
            margin-top: 2rem;
            margin-bottom: 2rem;
            box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);



            &:hover{
                background-color: #43db8f;
                cursor: pointer;
            }
            &:active{
                background-color: #4bf39f;
                cursor: pointer;
            }

            // &:active > &-text {
            //     color:white;
            // }

            &-text {
                color:white;
                font-size: 1rem;
                font-family: fredoka;
            }
        
    }
        &-no-btn {
            height: 3rem;
            width: 40%;
            background-color: white;
            border-radius: 1rem;
            display: flex;
            justify-content: center;
            align-items: center;
            transition: all .2s;
            user-select: none;
            margin-top: 2rem;
            margin-bottom: 2rem;
            border: 1px solid var(--Dark, #080E1E);
            box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);



            &:hover{
                background-color: #c4c4c4;
                cursor: pointer;
            }
            &:active{
                background-color: #ededed;
                cursor: pointer;
            }

            // &:active > &-text {
            //     color:white;
            // }

            &-text {
                color:#080E1E;
                font-size: 1rem;
                font-family: fredoka;
            }
        
    }

        

        
}