.overview-list {
    width: 50%;
    margin-left: 25%;
    margin-right: 25%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;

    @media only screen and (max-width: 1003px) {
        width: 80%;
        margin-left: 10%;
        margin-right: 10%;
    }


}