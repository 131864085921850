.course-list {
    width: 50%;
    margin-left: 25%;
    margin-right: 25%;
    margin-bottom: 5rem;


    @media only screen and (max-width: 1003px) {
        width: 80%;
        margin-left: 10%;
        margin-right: 10%;
    }

    


}