.start-quiz {
    padding:4rem 0 0 0;
    

    &-header{
        padding: 2rem 0;

        &-title {
            color: var(--Dark, #080E1E);
            font-family: fredoka;
            text-align: center;
        }
    }
    &-content{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }
    &-image{
        
        width: 30vw;
        height: 30vw;
        border-radius: 1.75rem;
        margin: 1rem 0 2rem 0;
        @media only screen and (max-width: 1003px) {
            width: 70vw;
            height: 70vw;
        }
    }



        &-btn {
            height: 8vh;
            width: 50%;
            background-color: var(--Green, #3ABF7C);
            border-radius: 1rem;
            display: flex;
            justify-content: center;
            align-items: center;
            transition: all .2s;
            user-select: none;


            &:hover{
                background-color: #43db8f;
                cursor: pointer;
            }
            &:active{
                background-color: #4bf39f;
                cursor: pointer;
            }

            // &:active > &-text {
            //     color:white;
            // }

            &-text {
                color:white;
                font-size: 1.2rem;
                font-family: fredoka;
            }
        
    }
}
