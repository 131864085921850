.landing {
    height: 100vh;
    background-image: url(./../assets/images/landing.png);
    background-size: 100%;
    background-position: 20%;
    background-repeat: no-repeat;
    padding:4rem 0 0 0;
    &-content {
        display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 75vh;
    }
    

    &-header{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding: 2rem 0;

        &-title {
            color:white;
        }
    }

    &-footer{
        background-color: rgba(58, 191, 124, 0.70);
        height: 15vh;
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        position: relative;

        &-text {
            font-family: fredoka;
            color: white;
            font-size: 1.5rem;
            margin-bottom: 1rem;
        }

        &-btn {
            height: 8vh;
            width: 50%;
            background-color: white;
            border-radius: 1rem;
            display: flex;
            justify-content: center;
            align-items: center;
            position: absolute;
            left: 25%;
            right: 25%;
            bottom: -4vh;
            transition: all .2s;
            user-select: none;


            &:hover{
                background-color: #e8e8e8;
                cursor: pointer;
            }
            &:active{
                background-color: white;
                cursor: pointer;
            }

            // &:active > &-text {
            //     color:white;
            // }

            &-text {
                color:var(--Green, #3ABF7C);
                font-size: 1.2rem;
                font-family: fredoka;
            }
        }
    }
}
