.course-item {
    width: 100%;
    height: 7rem;
    background-color: white;
    border-radius: 1rem;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
    
    padding:0 2rem;
    gap: 2rem;
    user-select: none;
    transition: all .2s;
    margin:2rem 0;

    &:hover {
        background-color: #FFEDF1;
        cursor: pointer;
    }
    &:active {
        background-color: #faaeca;
        cursor: pointer;
    }

    &-info{
        width: 100%;
        height:60%;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: flex-start;

        &-title {
            color: #43463F;
            font-feature-settings: 'clig' off, 'liga' off;
            font-family: fredoka;
            font-size: 1.0625rem;
            font-style: normal;
            font-weight: 500;
            line-height: 1.375rem; /* 129.412% */
            letter-spacing: -0.0255rem;
        }
        &-text {
            color: #50555C;
            font-feature-settings: 'clig' off, 'liga' off;
            font-family: fredoka;
            font-size: 0.625rem;
            font-style: normal;
            font-weight: 400;
            line-height: 1.375rem; /* 220% */
            letter-spacing: -0.0255rem;
        }

        &-progress {
            width: 100%;
            height: 1rem;
            background-color: #E5E5E5;
            border-radius: .5rem;

            &-done{
                height: 1rem;
                background-color: #3ABF7C;
                border-radius: .5rem;
            }
        }



        
    }

    &-icon {
        width: 3.70356rem;
        height: 3.5rem;
        flex-shrink: 0;
        border-radius: 0.9375rem;
        background: #3ABF7C;
        box-shadow: 0px 2px 0px 0px rgba(143, 86, 0, 0.80);
        display: flex;
        justify-content: center;
        align-items: center;

        &-image {
            width: 1.94925rem;
            height: 1.875rem;
        }
    }

    &-data {
        height:60%;
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        align-items: flex-end;
    }


    @media only screen and (max-width: 1003px) {
        width: 80%;
        margin-left: 10%;
        margin-right: 10%;
    }

    


}