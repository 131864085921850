.question-list {
    width: 50%;
    margin-left: 25%;
    margin-right: 25%;


    @media only screen and (max-width: 1003px) {
        width: 80%;
        margin-left: 10%;
        margin-right: 10%;
    }

    &-horizontal{
        width: 50%;
        margin-left: 25%;
        margin-right: 25%;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        gap: 2rem;

        @media only screen and (max-width: 1003px) {
            width: 80%;
            margin-left: 10%;
            margin-right: 10%;
        }
    }


}